import React from "react";
import H1 from "../../components/h1";
import SEO from "../../components/seo";

const Services = () => {
	const classNames = `mb-6`;
	return (
		<div>
			<SEO
				title="Our Services Gastroenterology, Hepatology and Endoscopy"
				keywords={[
					`Gastroenterology services`,
					`Endoscopy services`,
					`Gastroenterologist`,
					`Hepatologist`,
					`Dr Frank`,
					`Dr Brennan`,
					`Dr Spiro`,
					`Dr Raftopoulos`,
					`Dr Ian Yusoff`,
					`Dr Dev Segarajasingam`,
					`Dr Sam Rao`,
					`Dr Claire Gordon`,
					`Dr Duy Pham`,
				]}
				description="Services on offer at Advanced GI WA"
			/>
			<H1 text="Services" />
			<p className={classNames}>
				We offer a wide range of services including consultation in general gastroenterology, inflammatory bowel disease
				and hepatology. In addition we also offer all aspects of both general and interventional endoscopy. Below is a
				list of some of these procedures, which will be discussed in more detail at the time of your consultation.
			</p>
			<h2 className={`h2 ${classNames}`}>Upper Endoscopy / Gastroscopy</h2>
			<p className={classNames}>
				Gastroscopy (upper endoscopy) is a procedure that uses a flexible endoscope to see inside the upper digestive
				tract – including the oesophagus, stomach and the first part of the small intestine. During an upper endoscopy a
				thin flexible tube is carefully fed down the oesophagus into the stomach and the small intestine. A small camera
				on the endoscope transmits an image to a monitor, allowing close examination of the intestinal lining.
			</p>
			<p className={classNames}>
				It is used to determine the cause of abdominal pain, nausea, vomiting, swallowing difficulties, gastric reflux,
				unexplained weight loss, anaemia or bleeding in the upper digestive tract. Upper endoscopy is also used to
				remove stuck objects (including food), treat conditions such as bleeding ulcers and strictures and biopsy
				tissue. During a biopsy, a small piece of tissue is removed for later examination with a microscope.
			</p>
			<h2 className={`h2 ${classNames}`}>Colonoscopy</h2>
			<p className={classNames}>
				A colonoscopy is a procedure that examines the large bowel using a flexible tube with a digital camera. The
				image from the camera appears on a monitor to provide magnified view of the colon. The colonoscope is gently
				inserted through the anus and up into the colon. This procedure can be used to look for colon polyps or cancer
				of the colon and can be use to treat known advanced polyps of the bowel.
			</p>
			<h2 className={`h2 ${classNames}`}>Endoscopic retrograde cholangiopancreatography (ERCP)</h2>
			<p className={classNames}>
				Endoscopic retrograde cholangiopancreatography (ERCP) is a technique that combines the use of endoscopy and
				fluoroscopy to diagnose and treat certain problems of the digestive system such as gallstones, inflammatory
				strictures (scars) or cancer.
			</p>
			<p className={classNames}>
				The patient is sedated or anaesthetised. During the procedure a thin telescope (endoscope) is inserted down the
				throat to allow the doctor to see inside the digestive system.
			</p>
			<p className={classNames}>
				The doctor then injects a dye into the bile duct or pancreatic duct so it can be seen on an x-ray (fluroscopy)
				while the necessary procedure is performed.
			</p>
			<h2 className={`h2 ${classNames}`}>Endoscopic ultrasonography (EUS)</h2>
			<p className={classNames}>
				Endoscopic ultrasonography (EUS) allows your doctor to examine esophageal and stomach linings as well the walls
				of your upper and lower gastrointestinat tract. The doctor will use a thin, flexible tube called an endoscope
				that has built-in miniture ultrasound probe. The endoscope will passed through your mouth or anus to the area to
				be examined. The ultrasound is then used to create visual images of the digestive tract.
			</p>
			<h2 className={`h2 ${classNames}`}>Capsule Endoscopy</h2>
			<p className={classNames}>
				This procedure involves the ingestion of a small capsule that contains a tiny camera, which can take 2-4 images
				per second for eight hours. It has been specifically developed to view the small bowel.
			</p>
			<p className={classNames}>
				Capsule endoscopy is used to try and pinpoint bleeding in hidden areas in the small bowel. It is also useful for
				tracking small bowel tumours and obscure abdominal pain.
			</p>
			<p className={classNames}>
				The capsule is swallowed and travels, just like a piece of food, through the stomach, small bowel and into the
				large bowel. Recording probes, taped onto the abdomen, track the progress of the capsule as it makes its way
				through the gastrointestinal system. The images obtained by the capsule are transmitted to a data recorder worn
				in a harness around the waist.
			</p>
			<h2 className={`h2 ${classNames}`}>Advanced Endoscopic Resection</h2>
			<p className={classNames}>
				This procedure involves the removal of pre-malignant and early malignant lesions of the oesophagus, stomach,
				duodenunum or colon. These new techniques have enabled the provision of surgery sparing therapies that are
				most often performed as a day procedure with much lower morbidity and mortailiy when compared to the traditional
				surgical alternatives.
			</p>
			<p className={classNames}>
				These specialised techniques have been brought back to Western Australia following overseas fellowships in high
				volume, expert centres by several of the doctors at Advanced GI WA.
			</p>
			<p className={classNames}>
				Exact specifics relating to these specialised procedures will be discuss in greater detail during the
				consultative process.
			</p>
			<h2 className={`h2 ${classNames}`}>Per Oral Endoscopic Myotomy</h2>
			<p className={classNames}>
				This procedure is performed for the treatment of several oesophageal motility disorders including Achalasia,
				Diffuse Oesophageal Spasm and Jack Hammer Oesophagus. It involves the creation of a tunnel in the wall of the
				oesophagus along the length of muscle and then with a small needle knife, the muscle is cut followed by
				closure of the hole. Traditionally this was offered through a surgical approach however has now quickly
				become the standard of care in many centres around the world including Australia.
			</p>
			<p className={classNames}>
				Advanced GI WA was the first to introduce this technique into Western Australia and has been successfully
				performing this since 2017.
			</p>
			<h2 className={`h2 ${classNames}`}>Radiofrequency Ablation</h2>
			<p className={classNames}>
				This procedure is used for the eradication and treatment of Barrett's Oesophagus. It uses a combination of
				balloon's and flat paddles that apply a current to the lining of the Oesophagus burning it and thereby
				destroying the abnormal Barrett Cells and thereby allowing new healthy cells to grow back in its place.
			</p>
			<p className={classNames}>
				Most private health funds now fully cover the costs of this treatment in private as it allows treating
				doctors to provide an alternative to major thoracic surgery which comes at a much high risk of
				mortality and long term morbidity.
			</p>
		</div>
	);
};

export default Services;
